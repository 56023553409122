import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/docker-image-files",
  "date": "26th July 2024",
  "title": "A quick guide to portable Docker images",
  "summary": "How you can securely move your docker images around, without the internet!",
  "author": "Karl Hepworth",
  "tag": "Tooling",
  "tagColor": "blue",
  "tags": [{
    "name": "tooling"
  }, {
    "name": "development"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In today’s development landscape, when faced with additional security measures,
you may need to transfer docker images from one registry to another without
using a registry or the internet.`}</p>
    <p>{`Did you know that Docker has a command that allows you to retrieve and store a
Docker image as a file instead of keeping it in a registry? Docker has been
able to do it for a while now, so there’s nothing stopping you from adding
this to your workflow.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/posts/20240726/diagram.png",
        "alt": "Diagram of the API workflow used in this blog post."
      }}></img></p>
    <p>{`You might ask yourself, why is this helpful? However, consider what you might
do if you encounter one of the following situations:`}</p>
    <ul>
      <li parentName="ul">{`You need to transfer an image between two systems without using a registry.`}</li>
      <li parentName="ul">{`You need to transfer an image between two systems without using the internet.`}</li>
      <li parentName="ul">{`You want to archive a set of container images for potential later use.`}</li>
      <li parentName="ul">{`You need to deploy an image to an offline cluster.`}</li>
    </ul>
    <p>{`To save an image from your registry into a local file, you can use the
`}<inlineCode parentName="p">{`docker save`}</inlineCode>{` command. In the background, this will take each of the layers
from the Docker image in your registry, package them up into an archive and
output the file to the specified location. Many Docker-compatible tools such
as Podman will also feature this command as well as a way to ensure
cross-tooling can be used when needed.`}</p>
    <pre><code parentName="pre" {...{}}>{`$ docker save \${IMAGE_REFERENCE} -o ./docker-image.tar.gz
Writing manifest to image destination
`}</code></pre>
    <p>{`To load a Docker image from a file into your registry, you can use the
`}<inlineCode parentName="p">{`docker load`}</inlineCode>{` command. What this does in the background is accept a packaged
docker image created from the `}<inlineCode parentName="p">{`docker save`}</inlineCode>{` command and unload it into the
registry which is associated with the command being used. For example, you
could use this process to load a Docker image into a Podman repository, or
vice versa.`}</p>
    <pre><code parentName="pre" {...{}}>{`$ docker load -i ./docker-image.tar.gz
Loaded image: \${IMAGE_REFERENCE}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/images/posts/20240726/console.gif",
        "alt": "Gif of a verbose output when running these commands."
      }}></img></p>
    <h2>{`Final thoughts`}</h2>
    <p>{`Next time you want to share a container, perhaps you should consider this
option. Having a cross-compatible Docker image file is a huge advantage
that can be used and transferred  as securely as any other file.`}</p>
    <p>{`It’s a reliable and secure way to share your Docker images, which works with
any OCI-compliant image and should be part of your backup and decommissioning processes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      